import React, { createContext, useContext } from "react";

import { useColours, useNewColours, useAdverse } from "../api/index";

import { find } from "lodash";

const ParamsContext = createContext();

export function ParamsProvider(props) {
  const {
    data: coloursList,
    isError: colourError,
    isSuccess: colourFetched,
  } = useNewColours();

  const {
    data: adverseList,
    isError: adverseError,
    isSuccess: adverseFetched,
  } = useAdverse();

  function getColour(id) {
    if (colourFetched) {
      let colour;
      coloursList.map((item) => {
        item.colours.map((c) => {
          if (c.id === id) {
            colour = c;
            if (!colour.label) {
              colour["label"] = item.label;
            }
          }
        });
      });
      return colour;
    }
    if (colourError) {
      return { error: "Colours could not be fetched" };
    }
  }

  // function getColour(id) {
  //   if (colourFetched) {
  //     return find(coloursList, function (item) {
  //       return item.id === id;
  //     });
  //   }
  //   if (colourError) {
  //     return { error: "Colours could not be fetched" };
  //   }
  // }

  function getReaction(id) {
    if (adverseFetched) {
      return find(adverseList, function (item) {
        return item.id === id;
      });
    }
    if (adverseError) {
      return { error: "Adverse list could not be fetched" };
    }
  }

  function getAdverseList(reactions = null) {
    if (adverseFetched) {
      if (reactions) {
        const list = [];
        if (reactions) {
          for (let i = 0; i < reactions.categoryId; i++) {
            list.push(find(adverseList, reactions[i].categoryId).label);
          }
          return list;
        }
      }
      return adverseList;
    }
    return null;
  }

  function getAdverse(categories) {
    if (categories?.length) {
      const list = [];
      categories.forEach((reaction) => {
        const label = adverseList.find(
          (adverse) => adverse.id === reaction.categoryId
        )?.label;
        if (label) list.push(label);
      });

      return list;
    }
    return [];
  }

  return (
    <ParamsContext.Provider
      value={{ getColour, getReaction, getAdverseList, getAdverse }}
    >
      {props.children}
    </ParamsContext.Provider>
  );
}

export const useParamsContext = () => {
  const { getColour, getReaction, getAdverseList, getAdverse } =
    useContext(ParamsContext);
  return { getColour, getReaction, getAdverseList, getAdverse };
};
