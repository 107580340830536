import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const PrivateRoute = ({ component: Component, props, ...rest }) => {
  const [auth, setAuth] = useState(-1); // -1 = JWT missing/invalid, 1 = authorized, 2 = unauthorized
  const [finishedVerifying, setFinishedVerifying] = useState(false);

  useEffect(() => {
    // verify JWT before rendering page
    axios
      .get("/user/verifyJWT", { params: { path: rest.path } })
      .then(function (res) {
        if (res.data.auth === false) {
          setAuth(-1);
        } else if (res.data.auth && res.data.permissionLevel.includes("2")) {
          setAuth(1);
        } else if (res.data.auth && !res.data.permissionLevel.includes("2")) {
          setAuth(2);
        } else {
          console.log("PrivateRoute.js - unknown result");
        }
        setFinishedVerifying(true);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [rest.path]);

  if (!finishedVerifying) return <div />; // or some kind of loading animation

  return (
    <Route
      {...rest}
      render={(props) =>
        auth === 1 ? (
          <Component {...props} />
        ) : auth === -1 ? (
          <Redirect
            to={{
              pathname: `/login`,
              state: {
                message: "You must be logged in to view this page.",
              },
            }}
          />
        ) : (
          <Redirect to="/unauthorizedError" />
        )
      }
    />
  );
};

export default PrivateRoute;
