import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// Bootstrap 4 CSS and Bootstrap Icons
import "bootstrap/dist/css/bootstrap.min.css";

// redux for advanced state management
import { Provider } from "react-redux";
import { store } from "./helpers/Store";

// provider for api calls
import { QueryClient, QueryClientProvider } from "react-query";

// provider for local contexts
import { ParamsProvider } from "./contexts";

// Routes
import Routes from "./Routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ParamsProvider>
        <Routes />
      </ParamsProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);
