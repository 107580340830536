import { useState, useRef } from "react";
import { useQuery } from "react-query";
// import { Table, Input, Button, Spin, Modal } from "antd";
import { Table, Input, Spin, Modal } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";

// components
import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";
import { ColourCircleC } from "../components/ui/ColourCircle";
import DatePicker from "./../components/ui/DatePicker";
import ViewSample from "./ViewSamplePopup.js";

// api
import { recentSamples } from "./../api/logic";
import { useParamsContext } from "../contexts";
import { useUserSettings } from "../api/user";

// import "../styles/Recent.css";

export default function Recent() {
  const defaultFromDate = new Date(new Date() - 7 * 60 * 60 * 24 * 1000);
  const defaultToDate = new Date();
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [proposedFromDate, setProposedFromDate] = useState(
    dayjs(defaultFromDate)
  );
  const [proposedToDate, setProposedToDate] = useState(dayjs(defaultToDate));

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchRef = useRef(null);

  const [viewedSample, setViewedSample] = useState("");

  const { getColour, getAdverse } = useParamsContext();
  const {
    data: settings,
    isLoading: settingsLoading,
    isError: settingsError,
  } = useUserSettings();

  const { RangePicker } = DatePicker;
  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  Spin.setDefaultIndicator(spinIcon);

  // const {
  //   data: coloursList,
  //   isError: colourError,
  //   isSuccess: colourFetched,
  // } = useColours();
  // if(colourFetched) {
  //   const colourFilter = coloursList.map(obj => {
  //     let newObj = {};
  //     newObj["text"] = obj.label;
  //     newObj["value"] = obj.id;
  //     return newObj;
  //   });
  //   console.log("colourFilter:",colourFilter);
  // }

  const {
    data: samples,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    ["recent", { fromDate, toDate }, settings],
    () => recentSamples({ fromDate, toDate }),
    { keepPreviousData: false }
  );

  const clearViewedSample = () => {
    setViewedSample("");
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const bulkFilters = [];
  const traceFilters = [];
  const data =
    isLoading || isError
      ? []
      : samples.samples.map((item) => {
          let newItem = item; // Note: shallow copy

          // item.colour = renderToString(<ColourCircleC data={getColour(item.colourId)} />);
          newItem.colour = item.colourId;

          const bulkScans = item.tests.filter(
            (test) => test.scanType.toLowerCase() === "bulk-burst"
          );
          const bulkScanFindings = new Set();
          bulkScans.forEach((scan) => {
            scan.findings.forEach((o) =>
              bulkScanFindings.add(
                o.substance.charAt(0).toUpperCase() + o.substance.slice(1)
              )
            );
          });
          newItem.bulkScans = Array.from(bulkScanFindings).join(", ");

          bulkScanFindings.forEach((finding) => bulkFilters.push(finding)); // for dynamic filters

          const traceScans = item.tests.filter(
            (test) => test.scanType.toLowerCase() === "trace-burst"
          );
          const traceScanFindings = new Set();
          traceScans.forEach((scan) => {
            scan.findings.forEach((o) => {
              if (o.libraryName.match(/Model/i))
                traceScanFindings.add(
                  o.substance.charAt(0).toUpperCase() + o.substance.slice(1)
                );
            });
          });
          newItem.traceScans = Array.from(traceScanFindings).join(", ");

          traceScanFindings.forEach((finding) => traceFilters.push(finding)); // for dynamic filters

          newItem["isNoteworthy"] = item.noteworthy?.length > 0 ? "Yes" : "No"; // using newItem.noteworthy causes bug due to shallow copy
          newItem.tested = item.tested || "N/A";
          newItem.processedDate = item.processedDate.replace(
            /(T|:\d\d.000Z)/g,
            " "
          );

          newItem.adverse = getAdverse(item.categories).join(", ");
          return newItem;
        });

  const getBulkFilters = () => {
    return bulkFilters
      .filter(onlyUnique)
      .map((finding) => ({ text: finding, value: finding }));
  };

  const getTraceFilters = () => {
    return traceFilters
      .filter(onlyUnique)
      .map((finding) => ({ text: finding, value: finding }));
  };

  const getDateFilterProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: 8, display: "block" }}>
          <RangePicker
            allowClear={false}
            value={[proposedFromDate, proposedToDate]}
            onChange={(dates, dateStrings) => {
              if (dates?.length) {
                setProposedFromDate(dates[0]);
                setProposedToDate(dates[1]);
                if (
                  dates[0].isSame(defaultFromDate, "day") &&
                  dates[1].isSame(defaultToDate, "day")
                ) {
                  setSelectedKeys([]);
                } else {
                  setSelectedKeys(dates);
                }
              }
            }}
            ranges={{
              "7 Days (default)": [dayjs().subtract(7, "d"), dayjs()],
              "30 Days": [dayjs().subtract(30, "d"), dayjs()],
              "180 Days": [dayjs().subtract(180, "d"), dayjs()],
            }}
          />
        </div>
        <Button
          onClick={() => handleDateReset(clearFilters)}
          size='small'
          type='link'
          disabled={selectedKeys.length === 0}
        >
          Reset
        </Button>
        <Button
          type='primary'
          onClick={() => handleDateFilter(selectedKeys, confirm)}
          size='small'
          style={{ float: "right" }}
        >
          OK
        </Button>
      </div>
    ),
  });
  const handleDateFilter = (selectedKeys, confirm) => {
    confirm();
    setFromDate(proposedFromDate.toDate());
    setToDate(proposedToDate.toDate());
  };
  const handleDateReset = (clearFilters) => {
    clearFilters();
    setProposedFromDate(dayjs(defaultFromDate));
    setProposedToDate(dayjs(defaultToDate));
    setFromDate(defaultFromDate);
    setToDate(defaultToDate);
  };

  // Search filter with "Search" and "Reset" buttons:
  // const getSearchFilterProps = (dataIndex, title) => ({
  //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         placeholder={`Search ${title}`}
  //         value={selectedKeys[0]}
  //         onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ marginBottom: 8, display: 'block' }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           <SearchOutlined style={{ display: 'inline-block', verticalAlign: '10%' }}/>
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => handleSearchReset(clearFilters)}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Reset
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  //   onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  //   render: text =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ''}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  // Search filter with instant search while typing:
  const getSearchFilterProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 4 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(e.target.value);
            setSearchedColumn(dataIndex);
            confirm(false);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ display: "flex" }}
          allowClear
          prefix={<SearchOutlined style={{ marginRight: 2 }} />}
          ref={searchRef}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: async (visible) => {
      await timeout(1); // 0.001 second delay. idk it just needs this to work for some reason
      if (visible) searchRef.current.focus({ cursor: "all" });
    },
    render: (text) => {
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        );
      } else return text;
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearchReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };
  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const columns = [
    {
      key: "sampleId",
      title: "Sample ID",
      dataIndex: "sampleId",
      sorter: (a, b) => a.sampleId.localeCompare(b.sampleId),
      ...getSearchFilterProps("sampleId", "sample ID"),
    },

    {
      key: "processedDate",
      title: "Processed Date",
      dataIndex: "processedDate",
      sorter: (a, b) => new Date(a.processedDate) - new Date(b.processedDate),
      ...getDateFilterProps("sampleId", "sample ID"),
    },

    {
      key: "presumedSubstance",
      title: "Presumed Substance",
      dataIndex: "presumedSubstance",
      sorter: (a, b) => a.presumedSubstance.localeCompare(b.presumedSubstance),
      onFilter: (value, record) =>
        record.presumedSubstance.indexOf(value) === 0,
      filters: [
        { text: "Adderall®", value: "Adderall®" },
        { text: "Benzodiazepines", value: "Benzodiazepines" },
        { text: "Carfentanil", value: "Carfentanil" },
        { text: "Cocaine", value: "Cocaine" },
        { text: "Dextromethorphan (DXM)", value: "Dextromethorphan (DXM)" },
        { text: "Fentalog", value: "Fentalog" },
        { text: "Fentanyl", value: "Fentanyl" },
        { text: "Heroin", value: "Heroin" },
        { text: "Hydromorphone", value: "Hydromorphone" },
        { text: "Ketamine", value: "Ketamine" },
        { text: "MDMA", value: "MDMA" },
        { text: "Methamphetamine", value: "Methamphetamine" },
        { text: "Morphine", value: "Morphine" },
        { text: "Nitrazene", value: "Nitrazene" },
        { text: "Phencyclidine (PCP)", value: "Phencyclidine (PCP)" },
        { text: "Other", value: "Other" },
        { text: "Other depressant", value: "Other depressant" },
        { text: "Other dissociative drug", value: "Other dissociative drug" },
        {
          text: "Other prescription medication",
          value: "Other prescription medication",
        },
        { text: "Other opioid", value: "Other opioid" },
        { text: "Other stimulant", value: "Other stimulant" },
        { text: "Oxycodone", value: "Oxycodone" },
        { text: "Xanax®", value: "Xanax®" },
        { text: "Xylazine", value: "Xylazine" },
      ],
    },

    {
      key: "colour",
      title: "Colour",
      dataIndex: "colour",
      render: (colour) => <ColourCircleC data={getColour(colour)} />,
      sorter: (a, b) => a.colour.localeCompare(b.colour),
      onFilter: (value, record) => record.colour.indexOf(value) === 0,
      filters: [
        { text: "Black", value: "BLA" },
        { text: "Blue", value: "BLU" },
        { text: "Brown", value: "BRO" },
        { text: "Clear", value: "CLE" },
        { text: "Green", value: "GRE" },
        { text: "Orange", value: "ORA" },
        { text: "Pink", value: "PIN" },
        { text: "Red", value: "RED" },
        { text: "Teal", value: "TEA" },
        { text: "Violet", value: "VIO" },
        { text: "White", value: "WHI" },
        { text: "Yellow", value: "YEL" },
      ],
    },

    {
      key: "bulkScans",
      title: "Bulk Scans",
      dataIndex: "bulkScans",
      sorter: (a, b) => a.bulkScans.localeCompare(b.bulkScans),
      onFilter: (value, record) => record.bulkScans.indexOf(value) >= 0,
      filters: getBulkFilters(),
    },

    {
      key: "traceScans",
      title: "Trace Scans",
      dataIndex: "traceScans",
      sorter: (a, b) => a.traceScans.localeCompare(b.traceScans),
      onFilter: (value, record) => record.traceScans.indexOf(value) >= 0,
      filters: getTraceFilters(),
    },

    // {
    //   key: "isNoteworthy",
    //   title: "Noteworthy",
    //   dataIndex: "isNoteworthy",
    //   sorter: (a, b) => a.isNoteworthy.localeCompare(b.isNoteworthy),
    //   onFilter: (value, record) => record.isNoteworthy.indexOf(value) === 0,
    //   filters: [
    //     { text: "Yes", value: "Yes" },
    //     { text: "No", value: "No" },
    //   ],
    // },

    // {
    //   key: "tested",
    //   title: "Tested",
    //   dataIndex: "tested",
    //   sorter: (a, b) => a.tested.localeCompare(b.tested),
    //   onFilter: (value, record) => record.tested.indexOf(value) === 0,
    //   filters: [
    //     { text: "Pre-use", value: "Pre-use" },
    //     { text: "Post-use", value: "Post-use" },
    //     { text: "N/A", value: "N/A" },
    //   ],
    // },

    {
      key: "adverse",
      title: "Adverse Reactions",
      dataIndex: "adverse",
      sorter: (a, b) => a.adverse.localeCompare(b.adverse),
      onFilter: (value, record) => record.adverse.indexOf(value) >= 0,
      filters: [
        { text: "Physical stimulus", value: "Physical stimulus" },
        {
          text: "Light supplemental oxygen",
          value: "Light supplemental oxygen",
        },
        {
          text: "Heavy supplemental oxygen",
          value: "Heavy supplemental oxygen",
        },
        { text: "Ventilation", value: "Ventilation" },
        { text: "Nalaxone", value: "Nalaxone" },
        { text: "CPR", value: "CPR" },
      ],
    },

    {
      key: "action",
      title: "",
      dataIndex: "action",
      // render: (text,record) => (<a href={`/sample/${record._id}`}>View</a>),
      render: (text, record) => (
        <a href={"#" + record._id} onClick={() => setViewedSample(record._id)}>
          View
        </a>
      ),
    },
  ];

  // const columns = [
  //   { data: 'sampleId', title: "Sample ID" },
  //   { data: 'processedDate', title: "Processed Date" },
  //   { data: 'presumedSubstance', title: "Presumed Substance" },
  //   { data: 'colour', title: "Colour" },
  //   { data: 'bulkScans', title: "Bulk Scans" },
  //   { data: 'traceScans', title: "Trace Scans"},
  //   { data: 'noteworthy', title: "Noteworthy"},
  //   { data: 'tested', title: "Tested" },
  //   { data: 'adverse', title: "Adverse Reactions" },
  // ]
  // function checkSamples() {
  //   if (samples.samples) {
  //     return (
  //       <div className="h-100 mx-3" style={{ marginTop: "5rem" }}>
  //         <DataTable data={samples.samples} columns={columns} />
  //       </div>
  //     );
  //   }
  //   return <div className="h-100">There are no samples to display.</div>;
  // }

  const modifyExportHeaders = () => {
    //creating headers list for exporting data to CSV
    let exportHeaders = columns.map((col) => {
      return { label: col.title, key: col.key };
    });
    exportHeaders.pop(); //remove unused header for "action"
    exportHeaders.push({ label: "Notes", key: "description" }); //add the notes header
    exportHeaders = exportHeaders.map(
      (obj) => (obj.label === "Colour" ? { ...obj, key: "colourLabel" } : obj) //change the key for colours to export the label instead of the id
    );

    return exportHeaders;
  };

  const modifyExportData = () => {
    //create array of colour labels
    let exportData =
      isLoading || isError
        ? []
        : data.map((item) => {
            let c = getColour(item.colour);

            if (c?.id.includes("02") && c?.label !== "Light Grey") {
              return { ...item, colourLabel: `Light ${c?.label}` }; //adding "light" in front of colour label for light colours
            } else {
              return { ...item, colourLabel: c?.label };
            }
          });
    return exportData;
  };

  function checkSamples() {
    if (data) {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      today = dd + "/" + mm + "/" + yyyy;

      let exportHeaders = modifyExportHeaders();
      let exportData = modifyExportData();

      return (
        <div className='mx-3' style={{ marginTop: "6rem" }}>
          <div className='d-flex flex-row-reverse mb-3'>
            {!isLoading && (
              <CSVLink
                data={exportData}
                headers={exportHeaders}
                filename={`Dashboard_samples_${today}`}
                className='btn btn-primary mx-3'
              >
                Export to CSV
              </CSVLink>
            )}
          </div>
          <div className='mx-3'>
            <Table
              dataSource={data}
              columns={columns}
              loading={isLoading || settingsLoading}
            />
          </div>
        </div>
      );
    }
    return (
      <div style={{ marginTop: "6rem" }}>There are no samples to display.</div>
    );
  }

  return (
    <div>
      <Navbar authenticated={true} />
      {/* {(isLoading || settingsLoading) && (
          <div style={{ marginTop: "7rem" }}>
            <div className="alert alert-info mx-3" role="alert">
              Fetching samples...
            </div>
          </div>
        )} */}
      {isError || settingsError ? (
        <div style={{ marginTop: "7rem" }}>
          <div className='alert alert-danger mx-3' role='alert'>
            Something went wrong, try refreshing the page.
          </div>
        </div>
      ) : (
        checkSamples()
      )}
      <Modal
        title='View Sample'
        open={viewedSample}
        onCancel={clearViewedSample}
        width={"90%"}
        centered
        closable
        footer={null}
        // maskClosable={false}
        transitionName=''
        maskTransitionName=''
      >
        <ViewSample
          id={viewedSample}
          tableSample={data.find(({ _id }) => _id === viewedSample)}
          returnToTable={clearViewedSample}
        />
      </Modal>
      <Footer />
    </div>
  );
}
