import React from "react";

const Footer = () => {
  return (
    <footer className="footer bg-light py-5">
      <div className="container">
        <div className="small text-center text-muted">
          Copyright © 2024 - Spectra Plasmonics
        </div>
      </div>
    </footer>
  );
};
export default Footer;
