// react router for routing
import { Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes/PrivateRoute.js";
import { history } from "./helpers/history";
import "./App.css";

// pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import LocalView from "./pages/LocalView";
import Sample from "./pages/Sample";
import Unauthorized from "./pages/Unauthorized";
import Recent from "./pages/Recent";

export default function Routes() {
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Redirect exact from="/" to="/location" />
          <PrivateRoute exact path="/location" component={LocalView} />

          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/data-table" component={Recent} />

          <PrivateRoute exact path="/sample/:id" component={Sample} />
          {/* <PrivateRoute path="/national" component={NationalView} /> */}

          <Route exact path="/unauthorizedError" component={Unauthorized} />

          <Route exact path="*" page={<Redirect to="/" />} />
        </Switch>
      </Router>
    </div>
  );
}
