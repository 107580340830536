import React from "react";
import Chart from "chart.js";

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data = this.props.data;
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "bar",
      options: {
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }],
        },
        tooltips: {
          mode: "index",
          // intersect: false
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";
              return label + ": " + tooltipItem.value + " samples";
            },
            footer: function (tooltipItem, data) {
              var values = Array.from(tooltipItem.values());
              var adverse = values[0].yLabel;
              var nonAdverse = values[1].yLabel;
              var total = adverse + nonAdverse;
              return (
                "Total: " +
                total +
                " samples" +
                "\nReaction score: " +
                parseFloat(((adverse / total) * 100).toFixed(1)) +
                "%"
              );
            },
          },
        },
      },
      data: this.props.data,
    });
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}

export default BarChart;
