import React, { useState } from "react";

import LocationData from "../components/LocationData";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PeriodSelector from "../components/controls/PeriodSelector";

import { useUserLocations, useUserSettings } from "../api/user";

const LocalView = () => {
  const { data: locations, isLoading: locationsLoading, isError: locationsError } = useUserLocations();
  const { data: settings, isLoading: settingsLoading, isError: settingsError } = useUserSettings();
  
  const [period, setPeriod] = useState(7)

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod)
  }

  return (
    <div>
      <Navbar authenticated={true} />
      <div className="container-fluid mb-5">
        <header className="text-center">
          <div style={{ paddingTop: "8rem" }}>
            <h1 className="mb-2">{
              locationsLoading || settingsLoading
              ? <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              : locationsError || settingsError
                ? "Error. Please try again."
                : locations.filter(o => o.locationId === settings.currentLoc)[0].name
            }</h1>
          </div>
        </header>
        {
          settingsLoading
          ? <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          : settingsError
            ? "Error. Please try again."
            : <LocationData period={period} locId={settings.currentLoc} />
        }
      </div>
      {/* <BackButton /> */}
      <PeriodSelector
        selected={period}
        onPeriodChange={handlePeriodChange}
      />
      <Footer />
    </div>
  );
};

export default LocalView;
