import { adverse, colours, newColours } from "./logic";
import { useQuery } from "react-query";

export function useColours() {
  return useQuery("colours", colours);
}

export const useNewColours = () => {
  return useQuery("newColours", newColours);
};

export function useAdverse() {
  return useQuery("adverse", adverse);
}
