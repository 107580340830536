export function ColourCircleC({ data }) {
  if (!data || data.error) {
    return (
      <div className='d-flex'>
        <div
          className='shadow'
          style={{
            background: "white",
            borderColor: "lightgrey",
            borderStyle: "solid",
            borderWidth: "0.01rem",
            borderRadius: "10rem",
            width: "1.5rem",
            height: "1.5rem",
          }}
        ></div>
        <span className='ml-2 my-auto'>Error ...</span>
      </div>
    );
  }

  let colour = data.hexCode;

  if (data.label === "Clear") {
    colour =
      "repeating-linear-gradient(45deg,#ffffff,#ffffff 3px,#dbdbdb 3px,#dbdbdb 6px)";
  }
  return (
    <div className='d-flex'>
      <div
        className='shadow'
        style={{
          background: colour,
          borderColor: "lightgrey",
          borderStyle: "solid",
          borderWidth: "0.01rem",
          borderRadius: "10rem",
          width: "1.5rem",
          height: "1.5rem",
        }}
      ></div>
      <span className='ml-2 my-auto'>
        {data.label === "Light Grey"
          ? data.label
          : data.id.includes("02")
          ? `Light ${data.label}` //adding "light" in front of colour label for light colours
          : data.label}
      </span>
    </div>
  );
}
