export function SequenceB({ findings }) {
  return (
    <div className="border bg-light p-2">
      <div>
      <ul className="font-weight-bold align-middle mb-0">
          {Array.isArray(findings) ? (
            findings.length > 0 && typeof findings[0] === "string" && (
              findings.map((finding, index) => <li key={index}>{finding}</li>)
            ) 
          ) : (
            <li>No findings available</li>
          )}
        </ul>
      </div>
    </div>
  );
}

export function SequenceD({ findings, sequenceId }) {
  return (
    <div className="">
      <small className="font-weight-bold">Sequence {sequenceId}</small>
      <div className="">{findings.join(", ")}</div>
    </div>
  );
}

export function SequenceRadio({ flag, checked, select }) {
  return (
    <div>
      <input
        className="form-check-input"
        type="radio"
        name={flag}
        id={flag}
        checked={checked}
        onChange={() => select(flag)}
      />
      <label className="form-check-label" htmlFor={flag}>
        {flag === "" ? "N/A" : `${flag}`}
      </label>
    </div>
  );
}

export function SequenceRadioB({ name, value, id, checked, onChange }) {
  return (
    <div>
      <input
        className="form-check-input"
        type="radio"
        name={name}
        value={value}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={id}>
        {value === "" ? "N/A" : `${value}`}
      </label>
    </div>
  );
}
