import { applyMiddleware, createStore, compose } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import createReduxPromiseListener from "redux-promise-listener";
import rootReducer from '../reducers';

const reduxPromiseListener = createReduxPromiseListener();

const logger = createLogger({
    diff: true
});

let middleware = [thunk, reduxPromiseListener.middleware];

if (process.env.REACT_APP_BUILD_ENV !== "production") {
    middleware = [...middleware, logger];
}

middleware = applyMiddleware(...middleware);

export const store = createStore(rootReducer, compose(middleware));
