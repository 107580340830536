let user = JSON.parse(localStorage.getItem('user'))
const initialState = { isFetching: false, isAuthenticated: false, type: null, user: user}

export function authentication(state=initialState, action){
  switch(action.type){
    case 'LOGIN_REQUEST':
      return{
        type: 'LOGIN_REQUEST',
        isFetching: true,
        isAuthenticated: false,
        user: action.user,
      }
    case 'LOGIN_SUCCESS':
      return{
        type: 'LOGIN_SUCCESS',
        isFetching: false,
        isAuthenticated: true,
        user: action.user
      }
    case 'LOGIN_FAILURE':
      return{
        type: 'LOGIN_FAILURE',
        isFetching: false,
        isAuthenticated: false,
        message: 'error'
      }
    default:
      return state
  }
}
