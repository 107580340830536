/* eslint-disable import/first */

import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

import { useQuery } from "react-query";
import { recentSamples, sampleStats, commonDrugs, adverseReactions } from "./logic";

export function useSampleStats(ldate, hdate, drug) {
  return useQuery(
    ["byLoc", "sampleStats", ldate, hdate, drug],
    () => sampleStats(ldate, hdate, drug)
  );
}

export function useCommonDrugs(ldate, drug) {
  return useQuery(
    ["byLoc", "commonDrugs", ldate, drug], 
    () => commonDrugs(ldate, drug)
  )
}

export function useAdverseReactions(ldate, substance) {
  return useQuery(
    ["byLoc", "adverseReactions", ldate, substance],
    () => adverseReactions(ldate, substance)
  )
}

export function useRecentSamples({ fromDate, toDate }) {
  return useQuery(
    ["recent-samples", { fromDate, toDate }],
    () => recentSamples({ fromDate, toDate })
  );
}

export const useSample = (id) => {
  return useQuery(["sample", id], async () => {
    const { data } = await axios.get("/companion/view-edit-sample/get-sample", {
      params: { id: id },
    });
    return data;
  });
};
