import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

// * AUTH

export async function login({ email, password }) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
    baseURL: process.env.REACT_APP_API_URL,
  };
  const { data } = await axios("/user/login/", requestOptions);
  return data;
}

// * SAMPLES

export async function recentSamples({ fromDate, toDate }) {
  const { data } = await axios.get("/dashboard/sample-table/sample-list", {
    params: {
      fromDate: fromDate,
      toDate: toDate,
    },
  });
  return data;
}

export async function sample(id) {
  const { data } = await axios.get("/companion/view-edit-sample/get-sample", {
    params: { id: id },
  });
  return data;
}

export async function sampleStats(ldate, hdate, drug) {
  const { data } = await axios.post("/companion/body-stats/samples", {
    ldate,
    hdate,
    drug,
  });
  return data;
}

export async function commonDrugs(ldate, drug) {
  const { data } = await axios.post("/companion/body-stats/commonDrugs", {
    ldate,
    drug,
  });
  return data;
}

// * PARAMS

export async function adverse() {
  const { data } = await axios.get("/common/get-adverse");
  return data;
}

export async function colours() {
  const { data } = await axios.get("/common/get-colours");
  return data;
}

export async function newColours() {
  const { data } = await axios.get("/common/get-new-colours");
  return data;
}

export async function adverseReactions(ldate, substance) {
  const { data } = await axios.post("/companion/body-stats/adverseReactions", {
    ldate,
    substance,
  });
  return data;
}
