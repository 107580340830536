import { useState, useEffect } from "react";

// context calls
import { useParamsContext } from "./../contexts";

// components
import { ColourCircleC } from "./../components/ui/ColourCircle";
import { SequenceB } from "./../components/ui/Sequence";
import { SequenceRadioB } from "./../components/ui/Sequence";

export default function ViewSample(props) {
  // const { id } = useParams();
  const { id, tableSample, returnToTable } = props;

  // context
  const { getColour } = useParamsContext();

  // states
  const [colour, setColour] = useState();

  useEffect(() => {
      setColour(getColour(tableSample.colourId));
  }, [tableSample, getColour]);

  return (
    <div>

      <table className="table">
        <tbody>
          <tr>
            <th>Sample ID</th>
            <td>{tableSample.sampleId}</td>
          </tr>
          <tr>
            <th>Presumed substance</th>
            <td>{tableSample.presumedSubstance}</td>
          </tr>
          <tr>
            <th>Colour</th>
            <td>{colour && <ColourCircleC data={colour} />}</td>
          </tr>
          <tr>
            <th>Bulk scan(s)</th>
            <td>
              <div className="row">
                {tableSample.tests.length > 0
                  ? tableSample.tests
                      .filter((item) => item.findings.length > 0)
                      .filter(
                        (item) => item.scanType.toLowerCase() === "bulk-burst"
                      )
                      .map((item, index) => (
                        <div key={index} className="col-lg-4">
                          <SequenceB
                            findings={item.findings.map(
                              (o) =>
                                o.substance.charAt(0).toUpperCase() +
                                o.substance.slice(1)
                            )}
                          />
                        </div>
                      ))
                  : "N/A"}
              </div>
            </td>
          </tr>
          <tr>
            <th>Trace scan(s)</th>
            <td>
              <div className="row">
                {tableSample.tests.length > 0
                  ? tableSample.tests
                      .filter((item) => item.findings.length > 0)
                      .filter(
                        (item) =>
                          item.scanType.toLowerCase() === "trace-burst"
                      )
                      .map((item, index) => (
                        <div key={index} className="col-lg-4">
                          <SequenceB
                            findings={item.findings
                              .filter((o) =>
                                o.libraryName
                                  .toLowerCase()
                                  .includes("model")
                              )
                              .map(
                                (o) =>
                                  o.substance.charAt(0).toUpperCase() +
                                  o.substance.slice(1)
                              )}
                          />
                        </div>
                      ))
                  : "N/A"}
              </div>
            </td>
          </tr>
          {/* <tr>
            <th>Noteworthy</th>
            <td>
              {tableSample.isNoteworthy}
            </td>
          </tr> */}
          {/* <tr>
            <th>Tested</th>
            <td className="d-flex align-items-center">
              <div className="form-check">
                <SequenceRadioB
                  id="testedOpt1"
                  value="Pre-use"
                  name="testedFlag"
                  checked={tableSample.tested === "Pre-use"}
                  readOnly
                />
                <SequenceRadioB
                  id="testedOpt2"
                  value="Post-use"
                  name="testedFlag"
                  checked={tableSample.tested === "Post-use"}
                  readOnly
                />
                <SequenceRadioB
                  id="testedOpt3"
                  value=""
                  name="testedFlag"
                  checked={tableSample.tested === "N/A" || tableSample.tested === ""}
                  readOnly
                />
              </div>
              <div className="d-flex flex-row"></div>
            </td>
          </tr> */}
          <tr>
            <th>Adverse reactions</th>
            <td>
              {tableSample.adverse}
            </td>
          </tr>
          <tr>
            <th>Description</th>
            <td>
              <div className="form-outline w-50 mb-4">
                {tableSample.description}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  );
}
