import { userService } from "../services/auth";
import { history } from "../helpers/history";
// import { store } from "../helpers/Store";

export const userActions = {
  login,
};
function requestLogin(email) {
  //console.log(email);
  return {
    type: "LOGIN_REQUEST",
    isFetching: true,
    isAuthenticated: false,
  };
}

function receiveLogin(user) {
  return {
    type: "LOGIN_SUCCESS",
    isFetching: false,
    isAuthenticated: true,
    user,
  };
}

function loginError(message) {
  return {
    type: "LOGIN_FAILURE",
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

// function failedLogin(text) {
//   return {
//     type: "LOGIN FAILURE",
//     text
//   };
// }

// function logoutSucess(message) {
//   return {
//     type: "LOGOUT_SUCCESS",
//     isFetching: false,
//     isAuthenticated: false,
//     message
//   };
// }

export function login(email, password) {
  return (dispatch) => {
    dispatch(requestLogin({ email }));
    userService.login(email, password).then(
      (user) => {
        //let type = user.user.type;
        //Here we can have if statements to direct users to different pages after log in based on their role type
        dispatch(receiveLogin(user));
        history.push({
          pathname: `/`,
          state: [user],
        });
      },
      (error) => {
        dispatch(loginError(error));
      }
    );
  };
}
