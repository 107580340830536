import React from "react";
import { userActions } from "../actions/auth";
import { connect } from "react-redux";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

/* const Login = () => {
  return (
    <div className="login">
      <Navbar authenticated={false} />
      <div className="masthead"> 
        <div className="container"
         style={{
           paddingTop: "10rem"
         }}
        >
          <div
            className="bg-white p-5"
          >
            <Form className="y-5-auto">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" />
              </Form.Group>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Remember Me" />
              </Form.Group>

              <Button variant="primary" type="submit" href="/national">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Login; */


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      submitted: "",
      type: this.props.type,
      isFetching: this.props.isFetching,
      isAuthenticated: this.props.isAuthenticated,
    };
  }

  onHandle = (e) => {
    e.preventDefault();
    let { email, password } = this.state;
    this.props.dispatch(userActions.login(email, password));
  };
  onKeyHandle = (e) => {
    if (e.key === "Enter") {
      this.onHandle(e);
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.setState({
        isAuthenticated: this.props.isAuthenticated,
        isFetching: this.props.isFetching,
        type: this.props.type,
      });
    }
  }

  render() {
    let { type } = this.state;

    return (
      <div className="login">
        <Navbar />
        <header className="my-5 py-5"></header>
        <div className="mx-auto h-100 w-50 align-middle">
          <Form className="y-5-auto">
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                onChange={(e) => this.setState({ email: e.target.value })}
                type="email"
                placeholder="Enter email"
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => this.setState({ password: e.target.value })}
                onKeyDown={this.onKeyHandle}
              />
            </Form.Group>
            {type === "LOGIN_FAILURE" && (
              <div className="form-control mb-3 is-invalid">
                email and password do not match.
              </div>
            )}
            <Button variant="primary" type="submit" onClick={this.onHandle}>
              Submit
            </Button>
          </Form>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { type, isAuthenticated, isFetching } = state.authentication;
  return { type, isAuthenticated, isFetching };
}
export default connect(mapStateToProps)(Login);