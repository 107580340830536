import React, { useState, useEffect } from "react";

// Chart & stat components
import TrendingDrugs from "./TrendingDrugs";
import PastSixPeriods from "./PastSixPeriods";
import NumberVsCard from "./stats/NumberVsCard";

// UI
import CardA from "./ui/Cards";
import AdverseReaction from "./AdverseReactions";
import { DropdownButton } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";

// Data
import { useSampleStats, useCommonDrugs, useAdverseReactions } from "../api";

const LocationData = ({ period, locId }) => {
  // set default drug to all and default dataset to 7 day period
  const [drug, setDrug] = useState("All");

  const handleDrugChange = (e) => {
    setDrug(e);
  };

  // Initialize date variables
  const [now] = useState(new Date());
  const [onePeriodAgo, setOnePeriodAgo] = useState(
    new Date(Date.now() - period * 24 * 60 * 60 * 1000)
  );
  const [twoPeriodsAgo, setTwoPeriodsAgo] = useState(
    new Date(Date.now() - period * 24 * 60 * 60 * 1000)
  );

  // Update period if necessary
  useEffect(() => {
    console.log(period);
    setOnePeriodAgo(new Date(Date.now() - period * 24 * 60 * 60 * 1000));
    setTwoPeriodsAgo(new Date(Date.now() - 2 * period * 24 * 60 * 60 * 1000));
  }, [period]);

  // Call api functions
  const {
    data: pastWeek,
    isFetching: isFetchingPastWeek,
    isError: isErrorPastWeek,
  } = useSampleStats(onePeriodAgo, now, drug);
  const {
    data: past2Weeks,
    isFetching: isFetchingPast2Weeks,
    isError: isErrorPast2Weeks,
  } = useSampleStats(twoPeriodsAgo, onePeriodAgo, drug);
  const {
    data: commonDrugs,
    isFetching: isFetchingCommonDrugs,
    isError: isErrorCommonDrugs,
  } = useCommonDrugs(onePeriodAgo, drug);
  const {
    data: adverseReactions,
    isFetching: isFetchingAdverseReactions,
    isError: isErrorAdverseReactions,
  } = useAdverseReactions(onePeriodAgo, drug);
  let top5rxns;
  if (!isFetchingAdverseReactions && !isErrorAdverseReactions) {
    let adverse = adverseReactions.filter((rxn) => rxn._id.adverse);
    let regular = adverseReactions.filter((rxn) => !rxn._id.adverse);
    adverse.map((rxn) => {
      let match = regular.find(
        (r) => JSON.stringify(r._id.flat) == JSON.stringify(rxn._id.flat)
      );
      rxn.adverse_n = match !== undefined ? rxn.count : 0;
      rxn.reg_n = match !== undefined ? match.count : 0;
      rxn.total = rxn.adverse_n + rxn.reg_n;
    });
    regular.map((rxn) => {
      rxn.adverse_n = 0;
      rxn.reg_n = rxn.count;
      rxn.total = rxn.count;
    });
    top5rxns = adverseReactions
      .sort((a, b) => {
        return a.total < b.total;
      })
      .slice(0, 5);
  }

  const statDataNotReady = () => {
    return isFetchingPastWeek || isFetchingPast2Weeks;
  };

  const statDataError = () => {
    return isErrorPastWeek || isErrorPast2Weeks;
  };

  let pastWeekPresumptionPercent,
    pastWeekRiskPercent,
    pastWeekAdversePercent,
    pastWeekRisk;
  if (!isFetchingPastWeek && !isErrorPastWeek) {
    // Calculate number of presumed=actual tests in the last 7 and 14 days
    // console.log(pastWeek);
    const pastWeekPresumption = pastWeek.filter((sample) =>
      sample.tests.some((test) =>
        test.findings.some(
          (finding) =>
            finding.substance.toLowerCase() ===
            sample.presumedSubstance.toLowerCase()
        )
      )
    );
    // Calculate number of noteworthy samples in the last 7 and 14 days
    pastWeekRisk = pastWeek.filter(
      (sample) => sample.noteworthy && sample.noteworthy[0] === "Yes"
    );

    // Calculate number of adverse reactions in the last 7 and 14 days
    const pastWeekAdverse = pastWeek.filter(
      (sample) => sample.categories.length !== 0
    );

    // Don't divide by 0 if there are no samples
    pastWeekPresumptionPercent =
      pastWeek.length > 0
        ? (pastWeekPresumption.length / pastWeek.length) * 100
        : 0;
    pastWeekRiskPercent =
      pastWeek.length > 0 ? (pastWeekRisk.length / pastWeek.length) * 100 : 0;
    pastWeekAdversePercent =
      pastWeek.length > 0
        ? (pastWeekAdverse.length / pastWeek.length) * 100
        : 0;
  }

  let past2WeeksPresumptionPercent,
    past2WeeksRiskPercent,
    past2WeeksAdversePercent,
    past2WeeksRisk;
  if (!isFetchingPast2Weeks && !isErrorPast2Weeks) {
    // Same calculations as above
    const past2WeeksPresumption = past2Weeks.filter((sample) =>
      sample.tests.some((test) =>
        test.findings.some(
          (finding) =>
            finding.substance.toLowerCase() ===
            sample.presumedSubstance.toLowerCase()
        )
      )
    );
    past2WeeksRisk = past2Weeks.filter(
      (sample) => sample.noteworthy && sample.noteworthy[0] === "Yes"
    );
    const past2WeeksAdverse = past2Weeks.filter(
      (sample) => sample.categories.length !== 0
    );
    past2WeeksPresumptionPercent =
      past2Weeks.length > 0
        ? (past2WeeksPresumption.length / past2Weeks.length) * 100
        : 0;
    past2WeeksRiskPercent =
      past2Weeks.length > 0
        ? (past2WeeksRisk.length / past2Weeks.length) * 100
        : 0;
    past2WeeksAdversePercent =
      past2Weeks.length > 0
        ? (past2WeeksAdverse.length / past2Weeks.length) * 100
        : 0;
  }

  return (
    <div>
      <div className="d-flex mt-5 mb-3">
        <h2>Drug Supply Health</h2>
        <div className="my-auto ml-3">
          <DropdownButton size="md" onSelect={handleDrugChange} title={drug}>
            <Dropdown.Item eventKey="All">All</Dropdown.Item>
            <Dropdown.Item eventKey="Fentanyl">Fentanyl</Dropdown.Item>
            <Dropdown.Item eventKey="Cocaine">Cocaine</Dropdown.Item>
            <Dropdown.Item eventKey="Methamphetamine">
              Methamphetamine
            </Dropdown.Item>
            <Dropdown.Item eventKey="MDMA">MDMA</Dropdown.Item>
            <Dropdown.Item eventKey="Heroin">Heroin</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="row">
        {/* Overall stats */}
        <div className="col my-2">
          <CardA icon="fas fa-flask" title="Samples taken">
            {statDataNotReady() ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : statDataError() ? (
              <span>Error. Please try reloading the page.</span>
            ) : (
              <NumberVsCard
                value={pastWeek.length}
                vsValue={past2Weeks.length}
                vsText="vs previous"
              />
            )}
          </CardA>
        </div>
        {/* <div className="col my-2">
          <CardA icon="fas fa-crosshairs" title="Noteworthy outcomes">
            {statDataNotReady() ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : statDataError() ? (
              <span>Error. Please try reloading the page.</span>
            ) : (
              <NumberVsCard
                value={pastWeekRisk.length}
                vsValue={past2WeeksRisk.length}
                vsText="vs previous"
                inverse={true}
              />
            )}
          </CardA>
        </div>
        <div className="col my-2">
          <CardA
            icon="fas fa-exclamation-triangle"
            title="Risk score"
            help="Noteworthy outcomes / samples"
          >
            {statDataNotReady() ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : statDataError() ? (
              <span>Error. Please try reloading the page.</span>
            ) : (
              <NumberVsCard
                value={pastWeekRiskPercent.toFixed(1)}
                unit="%"
                vsValue={past2WeeksRiskPercent.toFixed(1)}
                vsText="vs previous"
                inverse={true}
              />
            )}
          </CardA>
        </div> */}
        <div className="col my-2">
          <CardA
            icon="fas fa-flag"
            title="Reaction score"
            help="Adverse reactions / samples"
          >
            {statDataNotReady() ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : statDataError() ? (
              <span>Error. Please try reloading the page.</span>
            ) : (
              <NumberVsCard
                value={pastWeekAdversePercent.toFixed(1)}
                unit="%"
                vsValue={past2WeeksAdversePercent.toFixed(1)}
                vsText="vs previous"
                inverse={true}
              />
            )}
          </CardA>
        </div>
        <div className="col my-2">
          <CardA
            icon="fas fa-eye"
            title="Presumption score"
            help="Correct presumptions / samples"
          >
            {statDataNotReady() ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : statDataError() ? (
              <span>Error. Please try reloading the page.</span>
            ) : (
              <NumberVsCard
                value={pastWeekPresumptionPercent.toFixed(1)}
                unit="%"
                vsValue={past2WeeksPresumptionPercent.toFixed(1)}
                vsText="vs previous"
              />
            )}
          </CardA>
        </div>
      </div>
      <div className="row">
        {/* Trending drugs */}
        <div className="col-lg-6 my-2">
          <CardA
            icon="fas fa-chart-pie"
            title="Common findings"
            subtitle={"Most common findings in the last " + period + " days"}
          >
            {isFetchingCommonDrugs ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : isErrorCommonDrugs ? (
              <span>Error. Please try reloading the page.</span>
            ) : (
              <TrendingDrugs commonDrugs={commonDrugs.slice(0, 10)} />
            )}
          </CardA>
        </div>
        <div className="col-lg-6 my-2">
          {drug === "All" ? (
            /* Local vs national risk */
            <CardA
              icon="fas fa-chart-line"
              title="Reaction score trend"
              subtitle={"7-day moving score for the past " + period + " days"}
            >
              <PastSixPeriods period={period} locId={locId} />
            </CardA>
          ) : (
            /* Adverse reactions */
            <CardA
              icon="fas fa-chart-bar"
              title={"Adverse reactions"}
              subtitle={"Top 5 drugs in the last " + period + " days"}
            >
              <div className="row">
                <div className="col-lg-12 my-2">
                  {isFetchingAdverseReactions ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : isErrorAdverseReactions ? (
                    <span>Error. Please try reloading the page.</span>
                  ) : (
                    <AdverseReaction
                      period={period}
                      locId={locId}
                      drug={drug}
                      rxns={top5rxns}
                    />
                  )}
                </div>
              </div>
            </CardA>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationData;
