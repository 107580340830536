import React from "react";
import Chart from "chart.js";

class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data = this.props.data
    this.myChart.options.scales.xAxes[0].time.unit = this.props.unit
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "line",
      options: {
        responsive: true,
        hover: {
          intersect: false
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontColor: "#333",
            fontSize: 16,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              // suggestedMax: 100,
              // min: 0,
              callback: function(label, index, labels) {
                return label+'%';
              }
            }
          }],
          xAxes: [{
            type: 'time',
            time: {
              unit: this.props.unit
            }
          }]
        }
      },
      data: this.props.data,
    });
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}

export default LineChart;
