/* eslint-disable import/first */
import React from "react";

// Environment variables

import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

// react router for routing
import { Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes/PrivateRoute.js";
import { history } from "./helpers/history";
import "./App.css";

// pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import LocalView from "./pages/LocalView";
import Unauthorized from "./pages/Unauthorized";
import Recent from "./pages/Recent";
import ViewSample from "./pages/Sample.js"
//import NationalView from "./pages/NationalView/NationalView";

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          {/* <Route exact path="/" page={<Redirect to="/location" />} /> */}

          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/data-table" component={Recent} />

          {/* id is an integer that will be passed into component to get location info */}
          <PrivateRoute exact path="/location" component={LocalView} />

          {/* <PrivateRoute exact path="/sample/:id" component={ViewSample} /> */}

          {/* <PrivateRoute path="/national" component={NationalView} /> */}

          <Route exact path="/unauthorizedError" component={Unauthorized} />

          <Route exact path="*">
            <Redirect to="/location" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
