import React from "react";
import Chart from "chart.js";

class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data = this.props.data;
    this.myChart.update();
  }

  componentDidMount() {
    Chart.defaults.global.defaultFontSize = 15;
    this.myChart = new Chart(this.canvasRef.current, {
      type: "doughnut",
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          position: "left",
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = parseFloat(
                ((currentValue / total) * 100).toFixed(1)
              );
              return " " + currentValue + " findings (" + percentage + "%)";
            },
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            },
          },
        },
      },

      data: this.props.data,
    });
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}

export default PieChart;
