import React from "react";
import { Radio } from "antd";

import "antd/dist/antd.css";

class PeriodSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e){
    this.props.onPeriodChange(e.target.value);
  }

  render() {
    return (
      <div className="float-right" style={{"position":"fixed", "top":"90px", "right":"1rem"}}>
        {/* using Radio from AntDesign docs here: https://ant.design/components/radio/ */}
        <Radio.Group
          defaultValue={this.props.selected}
          optionType="button"
          buttonStyle="solid"
          onChange={this.handleChange}
        >
          <Radio.Button value={7}>Past 7 days</Radio.Button>
          <Radio.Button value={30}>Past 30 days</Radio.Button>
          <Radio.Button value={180}>Past 180 days</Radio.Button>
        </Radio.Group>
      </div>
    );
  }
}

PeriodSelector.defaultProps = {
  selected: 7,
};

export default PeriodSelector;