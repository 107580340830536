import React from "react";
import palette from "../util/palette.js";

// Barchart component
import PieChart from "./charts/PieChart";

const TrendingDrugs = ({ commonDrugs }) => {
  const getData = () => {
    let data = {
      datasets: [
        {
          data: getLocationData(),
          backgroundColor: palette("mpn65", getLocationData().length).map(
            (hex) => "#" + hex
          ),
        },
      ],
      labels: getLabels(),
    };
    return data;
  };

  const getLocationData = () => {
    const data = commonDrugs.map((drug) => drug.total);
    return data;
  };

  const getLabels = () => {
    const data = commonDrugs.map((drug) => drug._id);
    return data;
  };

  return (
    <>
      {commonDrugs.length == 0 ? (
        <div
          className="bg-light"
          style={{ paddingTop: "50%", position: "relative" }}
        >
          <div style={{ top: "45%", left: "45%", position: "absolute" }}>
            No data
          </div>
        </div>
      ) : (
        <div>
          <PieChart data={getData()} />
          Click on a label above to make it disapear
        </div>
      )}
    </>
  );
};

export default TrendingDrugs;
