import React from "react";

// bar char component
import BarChart from "./charts/BarChart";

const AdverseReaction = ({ period, locId, drug, rxns }) => {
  // const getLabels = () => {
  //   if (period === 30) {
  //     var locData = localData30;
  //   } else if (period === 7) {
  //     var locData = localData7;
  //   } else if (period === 180) {
  //     var locData = localData180;
  //   }
  //   var newData = [];
  //   let data = locData.find((obj) => obj.loc_id == locId).data;
  //   data = data.find((obj) => obj.drug_type == drug).adverse_data;
  //   data = data.map((obj) => obj.adverseFinding);
  //   if (data.length > 4) {
  //     for (var i = 0; i < 5; i++) {
  //       newData[i] = data[i];
  //     }
  //   } else {
  //     newData = data;
  //   }
  //   console.log(newData)
  //   return newData;
  // };

  // const getDatasets = () => {
  //   if (period === 30) {
  //     var locData = localData30;
  //   } else if (period === 7) {
  //     var locData = localData7;
  //   } else if (period === 180) {
  //     var locData = localData180;
  //   }
  //   let data = locData.find((obj) => obj.loc_id == locId).data;
  //   data = data.find((obj) => obj.drug_type == drug).adverse_data;
  //   data = data.map((obj) => obj.numberOfAdverseFindings);
  //   let adverse = [];
  //   if (data.length > 4) {
  //     for (var i = 0; i < 5; i++) {
  //       adverse[i] = data[i];
  //     }
  //   } else {
  //     adverse = data;
  //   }
  //   let sampled = [];
  //   let data2 = locData.find((obj) => obj.loc_id == locId).data;
  //   data2 = data2.find((obj) => obj.drug_type == drug).adverse_data;
  //   data2 = data2.map((obj) => obj.numberOfNonAdverseFindings);
  //   if (data2.length > 4) {
  //     for (var i = 0; i < 5; i++) {
  //       sampled[i] = data2[i];
  //     }
  //   } else {
  //     sampled = data2;
  //   }
  //   let datasets = [
  //     {
  //       label: "Adverse",
  //       data: adverse,
  //       backgroundColor: "#BBB6DF",
  //     },
  //     {
  //       label: "Non-adverse",
  //       data: sampled,
  //       backgroundColor: "#3e517a",
  //     },
  //   ];
  //   console.log(datasets)
  //   return datasets;
  // };

  // const getData = () => {
  //   let data = {
  //     labels: getLabels(),
  //     datasets: getDatasets(),
  //   };
  //   return data;
  // };

  let rxnDatasets = [
    {
      label: "Adverse",
      data: rxns.map((rxn) => rxn.adverse_n),
      backgroundColor: "#BBB6DF",
    },
    {
      label: "Non-adverse",
      data: rxns.map((rxn) => rxn.reg_n),
      backgroundColor: "#3e517a",
    },
  ]

  let rxnData = {
    labels: rxns.map((rxn) => rxn._id.flat.join(", ")),
    datasets: rxnDatasets,
  }

  return (
    <div>
      <BarChart data={rxnData} title="Adverse Reactions" />
    </div>
  );
};

export default AdverseReaction;
