import { useState, useEffect } from "react";

// react router
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

// api calls
import { useSample } from "./../api";

// context calls
import { useParamsContext } from "./../contexts";

// components
import Footer from "./../components/Footer";
import Navbar from "./../components/Navbar";
import CardA from "./../components/ui/Cards";
import { ColourCircleC } from "./../components/ui/ColourCircle";
import { SequenceB } from "./../components/ui/Sequence";
import { SequenceRadioB } from "./../components/ui/Sequence";

import Button from "react-bootstrap/Button";

export default function ViewSample(props) {
  // const { id } = useParams();
  const { id, returnToTable } = props;

  // api
  const { data: sample, isError, isSuccess, isLoading } = useSample(id);

  // context
  const { getAdverseList, getAdverse, getColour } = useParamsContext();

  // states
  const [colour, setColour] = useState();
  const [submit, setSubmit] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setColour(getColour(sample.colourId));
      setDescription(sample.description);
    }
  }, [isSuccess, sample, getColour]);

  // reset submit after notification
  useEffect(() => {
    let timer = setTimeout(() => setSubmit(""), 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [submit]);

  const handleSelectFlag = (e) => {
    // console.log("editing disabled");
  };

  if (isError) {
    return (
      <div>
        <Navbar signedIn={false} />
        <div className="bg-white p-5 m-5 y-5-auto">
          <CardA title="Sample not found" />
        </div>
        <Footer />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Navbar signedIn={false} />
        <div className="bg-white p-5 m-5 y-5-auto">
          <div className="alert alert-info mx-3" role="alert">
            Fetching sample ...
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Navbar signedIn={false} />
      <div className="bg-white p-5 m-5 y-5-auto">
        {isSuccess && (
          <div className="w-75 mx-auto">
            <CardA>
              <table className="table">
                <tbody>
                  <tr>
                    <th>Sample ID</th>
                    <td>{sample.sampleId}</td>
                  </tr>
                  <tr>
                    <th>Presumed substance</th>
                    <td>{sample.presumedSubstance}</td>
                  </tr>
                  <tr>
                    <th>Colour</th>
                    <td>{colour && <ColourCircleC data={colour} />}</td>
                  </tr>
                  <tr>
                    <th>Bulk scan(s)</th>
                    <td>
                      <div className="row">
                        {sample.tests.length > 0
                          ? sample.tests
                              .filter((item) => item.findings.length > 0)
                              .filter(
                                (item) =>
                                  item.scanType.toLowerCase() === "bulk-burst"
                              )
                              .map((item, index) => (
                                <div key={index} className="col-lg-4">
                                  <SequenceB
                                    sequenceId={item.sequenceId}
                                    findings={item.findings.map(
                                      (o) =>
                                        o.substance.charAt(0).toUpperCase() +
                                        o.substance.slice(1)
                                    )}
                                  />
                                </div>
                              ))
                          : "N/A"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Trace scan(s)</th>
                    <td>
                      <div className="row">
                        {sample.tests.length > 0
                          ? sample.tests
                              .filter((item) => item.findings.length > 0)
                              .filter(
                                (item) =>
                                  item.scanType.toLowerCase() === "trace-burst"
                              )
                              .map((item, index) => (
                                <div key={index} className="col-lg-4">
                                  <SequenceB
                                    sequenceId={item.sequenceId}
                                    findings={item.findings
                                      .filter((o) =>
                                        o.libraryName
                                          .toLowerCase()
                                          .includes("model")
                                      )
                                      .map(
                                        (o) =>
                                          o.substance.charAt(0).toUpperCase() +
                                          o.substance.slice(1)
                                      )}
                                  />
                                </div>
                              ))
                          : "N/A"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Noteworthy</th>
                    <td>
                      {sample.noteworthy
                        ? sample.noteworthy.length === 0
                          ? "No"
                          : "Yes"
                        : "No"}
                    </td>
                  </tr>
                  <tr>
                    <th>Tested</th>
                    <td className="d-flex align-items-center">
                      <div className="form-check">
                        <SequenceRadioB
                          id="testedOpt1"
                          value="Pre-use"
                          name="testedFlag"
                          checked={sample.tested === "Pre-use"}
                          onChange={handleSelectFlag}
                        />
                        <SequenceRadioB
                          id="testedOpt2"
                          value="Post-use"
                          name="testedFlag"
                          checked={sample.tested === "Post-use"}
                          onChange={handleSelectFlag}
                        />
                        <SequenceRadioB
                          id="testedOpt3"
                          value=""
                          name="testedFlag"
                          checked={sample.tested === ""}
                          onChange={handleSelectFlag}
                        />
                      </div>
                      <div className="d-flex flex-row"></div>
                    </td>
                  </tr>
                  <tr>
                    <th>Adverse reactions</th>
                    <td>{getAdverse(sample.categories).join(", ")}</td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td>
                      <div className="form-outline w-50 mb-4">
                        {description}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex flex-row-reverse">
                {/* <Link className="btn btn-secondary m-2" to="/data-table">
                  Close
                </Link> */}
                <Button
                  className="btn btn-secondary m-2"
                  href="#!"
                  onClick={returnToTable}
                >
                  Close
                </Button>
              </div>
            </CardA>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
