import { React } from "react";

import { useLocalReactionChart } from "../api/charts"

// Barchart component
import LineGraph from "./charts/LineGraph";

const PastSixPeriods = ({ period, locId }) => {
  // const { data: localRiskData, isLoading: localRiskDataLoading, isError: localRiskDataError } = useLocalRiskChart(period, locId);
  const { data: localReactionData, isLoading: localReactionDataLoading, isError: localReactionDataError } = useLocalReactionChart(period, locId);

  const datasets = {
    7: {
      unit: "day",
    },
    30: {
      unit: "week",
    },
    180: {
      unit: "month",
    },
  };

  const getLocalData = () => {
    // console.log(localData);
    // eslint-disable-next-line
    // console.log(localRiskData)
    if (localReactionDataLoading) return []
    let data = localReactionData.dailyAvg.map((obj) => {
      return {
        y: (obj.score * 100).toFixed(2),
        x: Date.parse(obj.date),
      };
    });
    return data;
  };

  const getNationalData = () => {
    let nationalData = datasets[period].national;
    let data = nationalData.map((obj) => {
      return {
        y: (obj.score * 100).toFixed(2),
        x: Date.parse(obj.date.replace(" ", "T")),
      };
    });
    return data;
  };

  const getUnit = () => {
    return datasets[period].unit;
  };

  const getData = () => {
    let data = {
      datasets: [
        {
          label: "Local reaction score",
          data: getLocalData(),
          backgroundColor: "#70cad1",
          borderColor: "#a8e0ff",
          fill: false,
          pointRadius: 0,
        },
        // {
        //   label: "Local risk score",
        //   data: getLocalData(),
        //   backgroundColor: "#70cad1",
        //   borderColor: "#a8e0ff",
        //   fill: false,
        //   pointRadius: 0,
        // },
        // {
        //   label: "National risk score",
        //   data: getNationalData(),
        //   backgroundColor: "#b08ea2",
        //   borderColor: "#BBB6DF",
        //   fill: false,
        //   pointRadius: 0,
        // },
      ],
    };

    return data;
  };

  if (localReactionDataLoading) {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }

  if (localReactionDataError) {
    return <span>Error: {localReactionDataError.message}</span>
  }

  return (
    <div>
        <LineGraph data={getData()} unit={getUnit()} />
    </div>
  );
};

export default PastSixPeriods;
