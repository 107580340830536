import React from "react";

const CardA = ({icon, title, subtitle, help, children}) => {

  return (
    <div className="shadow card">
      <div className="card-body">
        {icon !== "" && (
          <i className={"float-right mt-1 " + icon}></i>
        )}
        <h5 className="card-title">
          {title} 
          {help !== "" && (
            <span style={{fontSize: "1rem"}}>
              <i 
                className="ml-2 far fa-question-circle text-primary"
                data-toggle="tooltip"
                data-placement="top"
                title={help}
              ></i>
            </span>
          )}
        </h5>
        <h6 className="card-subtitle mb-4 text-muted">
          {subtitle}
        </h6>
        {children}
      </div>
    </div>
  )
}

CardA.defaultProps = {
  icon: "",
  title: "",
  subtitle: "",
  help: ""
};

export default CardA;
