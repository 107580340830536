/* eslint-disable import/first */

import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
import { useQuery } from "react-query";

export const useLocalRiskChart = (period, location) => {
  return useQuery(["byLoc","localRiskChart", period, location], async () => {
    const { data } = await axios.get("/dashboard/charts/risk-score/local",{
      params: {
        period: period,
        location: location
      },
    });
    return data;
  });
};
export const useLocalReactionChart = (period, location) => {
  return useQuery(["byLoc","localReactionChart", period, location], async () => {
    const { data } = await axios.get("/dashboard/charts/reaction-score/local",{
      params: {
        period: period,
        location: location
      },
    });
    return data;
  });
};